var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import axios from 'axios';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import debounce from 'lodash/debounce';
import departementsData from './departements.json';
import { ApiResourceName } from '@shared/types/api/api';
import { ClaimTypeTrigram, } from '@shared/types/api/claim';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
import { fetchResourceCollection } from '@backoffice/utils/api';
import { iriFromId } from '@shared/utils/IriFromId';
import { Step } from '@shared/claim/steps';
import { uuidFromIri } from '@shared/utils/iri';
import { INSURER_CONFIG } from '@shared/insurer';
import { TABS } from '@shared/types/api/backoffice';
const claimTypes = namespace('claimTypes');
let FormDeclarationDepartement = class FormDeclarationDepartement extends Vue {
    constructor() {
        super(...arguments);
        this.users = [];
        this.contracts = [];
        this.selectedUser = null;
        this.selectedContract = null;
        this.selectedClaimType = null;
        this.filteredDepartments = [];
        this.userDepartment = '';
        this.isFindingUsers = false;
        this.isFindingDepartment = false;
        this.isFindingContracts = false;
        this.isCreatingClaim = false;
        this.isModalOpen = false;
        this.caseNumber = '';
        this.userInputDepartment = '';
        this.selectedDepartment = null;
    }
    onTyping(value) {
        this.findDepartment(value !== null && value !== void 0 ? value : '');
    }
    clearedDpt(option) {
        return option;
    }
    onDepartmentSelected(option, _$event) {
        var _a;
        if (option) {
            this.selectedDepartment = option;
            this.userInputDepartment = (_a = option.code) !== null && _a !== void 0 ? _a : '';
            if (this.selectedUser && this.userInputDepartment) {
                this.findContracts(this.selectedUser, this.selectedDepartment.code);
            }
        }
        else {
            this.selectedDepartment = null;
            this.userInputDepartment = '';
        }
    }
    get ddeClaimType() {
        return this.availableClaimTypes.find((claimType) => claimType.trigram === ClaimTypeTrigram.WaterDamage);
    }
    get departments() {
        return departementsData.map((dpt) => {
            return { code: dpt.code, nom: dpt.nom };
        });
    }
    get departmentCode() {
        var _a, _b;
        return (_b = (_a = this.selectedDepartment) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : '';
    }
    get isValid() {
        return this.caseNumber && this.departmentCode;
    }
    async createClaim() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (!this.isValid || this.isCreatingClaim)
            return;
        this.isCreatingClaim = true;
        await this.findUsers();
        if (this.selectedUser === null) {
            return;
        }
        await this.findContracts(this.selectedUser, this.departmentCode);
        const payload = {
            caseNumber: this.caseNumber,
            contract: (_a = this.selectedContract) === null || _a === void 0 ? void 0 : _a.id,
            policyholder: iriFromId(ApiResourceName.Users, this.selectedUser.id),
            type: (_b = this.ddeClaimType) === null || _b === void 0 ? void 0 : _b.id,
        };
        let claim = null;
        try {
            claim = await this.$axios.$post(apiResourcePath(ApiResourceName.Claims), payload);
            await this.$axios.$post(apiResourcePath(ApiResourceName.Countings), {
                title: 'Chiffrage',
                claim: claim.id,
            });
            await this.$axios.$put(`/claims-brms/${uuidFromIri(claim.id)}`, {
                step: Step.ClaimDate,
                type: (_c = this.ddeClaimType) === null || _c === void 0 ? void 0 : _c.id,
                isCreationFromBackoffice: true,
            });
            const routeId = ((_d = INSURER_CONFIG.backoffice) === null || _d === void 0 ? void 0 : _d.claimUrlIdentifier)
                ? claim[INSURER_CONFIG.backoffice.claimUrlIdentifier]
                : uuidFromIri(claim.id);
            const defaultTab = (_f = (_e = INSURER_CONFIG.backoffice) === null || _e === void 0 ? void 0 : _e.defaultTab) !== null && _f !== void 0 ? _f : TABS.CLAIM;
            this.$goToLocalePath({
                name: `declarations-id-${defaultTab}`,
                params: { id: routeId },
                query: { mode: 'edition' },
            });
        }
        catch (err) {
            this.$buefy.toast.open({
                message: axios.isAxiosError(err) && ((_g = err === null || err === void 0 ? void 0 : err.response) === null || _g === void 0 ? void 0 : _g.data['hydra:description'])
                    ? this.$tc('snackbar.error.' + ((_h = err === null || err === void 0 ? void 0 : err.response) === null || _h === void 0 ? void 0 : _h.data['hydra:description']))
                    : this.$tc('snackbar.error.server_error'),
                type: 'is-danger',
            });
        }
        finally {
            this.isCreatingClaim = false;
        }
    }
};
__decorate([
    claimTypes.Getter('availableClaimTypes')
], FormDeclarationDepartement.prototype, "availableClaimTypes", void 0);
FormDeclarationDepartement = __decorate([
    Component({
        async fetch() {
            await this.findUsers();
            await this.$store.dispatch('claimTypes/loadClaimTypes');
        },
        methods: {
            findDepartment: debounce(function () {
                this.isFindingDepartment = true;
                this.filteredDepartments = this.departments.filter((dpt) => {
                    return dpt.code
                        .concat('', dpt.nom)
                        .toString()
                        .toLowerCase()
                        .includes(this.userInputDepartment.toLowerCase());
                });
                this.isFindingDepartment = false;
            }, 500),
            async findUsers() {
                this.isFindingUsers = true;
                this.selectedUser = await this.$axios
                    .get(`${apiResourcePath(ApiResourceName.Users)}?email=john.doe@claims-ia.tech`)
                    .then((response) => {
                    return response.data['hydra:member'][0];
                });
            },
            async findContracts(user, departmentId) {
                this.isFindingContracts = true;
                await fetchResourceCollection(ApiResourceName.Contracts, this.$axios, {
                    user: user.id,
                    'department.id': departmentId,
                })
                    .then(({ data }) => {
                    this.selectedContract = data[0];
                    return data;
                })
                    .catch(() => []);
                this.isFindingContracts = false;
            },
        },
    })
], FormDeclarationDepartement);
export default FormDeclarationDepartement;
